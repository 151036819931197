import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'helpers/apiClient';

export const validateInvitation = createAsyncThunk('auth/validateInvitation', async (id, { rejectWithValue }) => {
  const data = {
    invitationId: `${id}`,
  };

  try {
    const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/invitationvalidate`, data, false);
    const res = await response.json();
    return res;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setPassword = createAsyncThunk('auth/setPassword', async (values) => {
  const data = {
    password: `${values.password}`,
    invitationId: `${values.invitationId}`,
  };

  const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/setpassword`, data, false);
  const res = await response.json();
  return res;
});

export const userLogin = createAsyncThunk('auth/userLogin', async (values, { rejectWithValue }) => {
  const data = {
    email: `${values.email}`,
    password: `${values.password}`,
  };
  try {
    const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/login`, data, false);
    console.log(response);
    const res = await response.json();
    return res;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (values, { rejectWithValue }) => {
  const data = {
    email: `${values.email}`,
  };

  try {
    const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/forgotpassword`, data, false);
    const res = await response.json();
    return res;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (values, { rejectWithValue }) => {
  const data = {
    password: `${values.password}`,
    uid: `${values.uid}`,
  };

  try {
    const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/resetpassword`, data, false);
    const res = await response.json();
    return res;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUser = createAsyncThunk('auth/getUser', async (value, { rejectWithValue }) => {
  try {
    const response = await api('GET', `${process.env.REACT_APP_API_BASE_URL}/user`, null, true);
    const res = await response.json();
    return res;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUrl = createAsyncThunk('auth/getUrl', async (value, { rejectWithValue }) => {
  try {
    const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/geturl`, null, true);
    const res = await response.json();
    return res;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authLoading: false,
    authError: false,
    isAuthenticated: false,
    passwordSet: false,
    invitationVerified: false,
    forgotPasswordSent: false,
    passwordReset: false,
    quiz1: false,
    quiz2: false,
    pdf: false,
    getUrlPending: false,
    quiz1Report: false,
    quiz2Report: false,
    quizReportError: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.authLoading = false;
      state.authError = false;
      state.isAuthenticated = false;
      state.passwordSet = false;
      state.invitationVerified = false;
      state.forgotPasswordSent = false;
      state.passwordReset = false;
      state.quiz1 = false;
      state.quiz2 = false;
      state.pdf = false;
      state.error = null;
      state.getReportsPending = false;
      state.quiz1Report = false;
      state.quiz2Report = false;
      state.quizReportError = false;
      localStorage.clear();

      return state;
    },
  },
  extraReducers(builder) {
    builder
      // validateInvitation
      .addCase(validateInvitation.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(validateInvitation.fulfilled, (state, action) => {
        if (action.payload.message === 'Password already set') {
          state.passwordSet = true;
          state.invitationVerified = true;
        }

        if (action.payload.message === 'Invitation valid') {
          state.invitationVerified = true;
        }

        state.authLoading = false;
      })
      .addCase(validateInvitation.rejected, (state, action) => {
        state.error = action.payload.message;
        state.authLoading = false;
        state.authError = true;
      })
      // setPassword
      .addCase(setPassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(setPassword.fulfilled, (state, action) => {
        localStorage.setItem('token', JSON.stringify(action.payload.token));
        localStorage.setItem('refreshToken', JSON.stringify(action.payload.refreshToken));
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        state.passwordSet = true;
        state.isAuthenticated = true;
        state.authLoading = false;
      })
      .addCase(setPassword.rejected, (state) => {
        state.authLoading = false;
        state.authError = true;
      })
      // forgotPassword
      .addCase(forgotPassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.authLoading = false;
        state.forgotPasswordSent = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.authLoading = false;
        state.authError = true;
        state.error = action.payload.email_delivery.error;
      })
      // resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.authLoading = false;
        state.passwordReset = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.authLoading = false;
        state.authError = true;
        state.error = action.payload.message;
        localStorage.clear();
        state.isAuthenticated = false;
      })
      // userLogin
      .addCase(userLogin.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        localStorage.setItem('token', JSON.stringify(action.payload.token));
        localStorage.setItem('refreshToken', JSON.stringify(action.payload.refreshToken));
        localStorage.setItem('user', JSON.stringify(action.payload.user));
        state.isAuthenticated = true;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.authLoading = false;
        state.authError = true;
        state.error = action.payload.message;
      })
      // getUser
      .addCase(getUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.quiz1 = action.payload.quiz1;
        state.quiz2 = action.payload.quiz2;
        state.pdf = action.payload.pdf;
        state.isAuthenticated = true;
        state.authLoading = false;
        state.passwordSet = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.authLoading = false;
        state.authError = false;
        state.isAuthenticated = false;
        state.passwordSet = false;
        state.invitationVerified = false;
        state.forgotPasswordSent = false;
        state.passwordReset = false;
        state.quiz1 = false;
        state.quiz2 = false;
        state.pdf = false;
        localStorage.clear();
      })
      // getUser
      .addCase(getUrl.pending, (state) => {
        state.getReportsPending = true;
      })
      .addCase(getUrl.fulfilled, (state, action) => {
        state.getReportsPending = false;
        state.quiz1Report = action.payload.quiz1Report;
        state.quiz2Report = action.payload.quiz2Report;
      })
      .addCase(getUrl.rejected, (state, action) => {
        state.getReportsPending = false;
        state.quizReportError = 'getUrl.rejected triggered';
        console.log(action.payload);
      });
  },
});

export const { clearState } = authSlice.actions;

export default authSlice.reducer;
