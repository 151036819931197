/* eslint-disable no-param-reassign */
const ordinalSuffix = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};

export const formatter = (timezone, time) => new Date(time).toLocaleTimeString(
  'en-GB',
  {
    hour: 'numeric',
    minute: 'numeric',

    timeZone: timezone,
  },
);
export const formatTime = (start, end, timezone) => {
  start = formatter(timezone, start).toLowerCase();
  end = formatter(timezone, end).toLowerCase();
  return `${start} - ${end}`;
};

export const formatDate = (date, options) => new Date(date).toLocaleDateString(
  window.navigator.language,
  options,
);

export const getStandardDate = (timestamp) => {
  const weekday = formatDate(timestamp, { weekday: 'long' });
  let date = formatDate(timestamp, { day: '2-digit' });
  date = parseInt(date, 10);
  date += `<sup>${ordinalSuffix(date)}</sup>`;
  const month = formatDate(timestamp, { month: 'long' });
  const year = formatDate(timestamp, { year: 'numeric' });
  return `${weekday} ${date} ${month} ${year}`;
};
