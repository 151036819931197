import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';
import { clearState } from 'features/auth/authSlice';
import { history } from 'helpers/history';
import menuIcon from 'assets/icons/dots.svg';

const NavBar = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;

  const handleSignOut = () => {
    dispatch(clearState());
    history.push('/login');
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Link to={isAuthenticated ? '/dashboard' : '/login'} className="navbar-brand">
            <Logo className="" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <img src={menuIcon} className="menu" alt="menu icon" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => handleSignOut()}>Log out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
