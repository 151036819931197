import { configureStore } from '@reduxjs/toolkit';

import quizReducer from '../features/quiz/quizSlice';
import authReducer from '../features/auth/authSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';

export default configureStore({
  reducer: {
    quiz: quizReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
  },
});
