import React from 'react';

export const Spinner = ({ text = '', size = 'em', color = 'text-dark' }) => {
  // const header = text ? <h4>{text}</h4> : null;
  return (
    <div className={`spinner-grow ${color}`} style={{ height: size, width: size }} role="status">
      {/* <span className="visually-hidden">{header}</span> */}
      <span className="visually-hidden"></span>
    </div>
  );
};
