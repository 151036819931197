import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'helpers/apiClient';

const initialState = {
  currentCategory: 1,
  categories: [],
  questions: [],
  results: [],
  status: 'idle',
};

export const getQuiz = createAsyncThunk('quiz/getQuiz', async (quizId) => {
  const data = { quizId: quizId };
  const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/getquiz`, data, true);
  const res = await response.json();
  return res.quiz;
});

export const saveQuiz = createAsyncThunk('quiz/saveQuiz', async (data) => {
  const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/savequiz`, data, true);
  const res = await response.json();
  return res;
});

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getQuiz.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        state.currentCategory = action.payload.currentCategory;
        state.categories = action.payload.categories;
        state.questions = action.payload.questions;
        if (action.payload.completed === 1) {
          state.status = 'completed';
        } else {
          state.status = 'succeeded';
        }
      })
      .addCase(getQuiz.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveQuiz.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(saveQuiz.fulfilled, (state, action) => {
        state.status = action.payload.completed === 1 ? 'completed' : 'succeeded';
        state.currentCategory = action.payload.nextCategory;
      })
      .addCase(saveQuiz.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { saveAnswer } = quizSlice.actions;

export default quizSlice.reducer;
