import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { forgotPassword } from 'features/auth/authSlice';
import { history } from '../../helpers/history';
import AuthLayout from 'components/Layouts/AuthLayout';
import Message from 'components/Auth/Message';
import responsiveHero from 'components/Auth/ResponsiveHero';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email required'),
});

const Forgot = () => {
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const auth = useSelector((state) => state.auth);
  const { authError, isAuthenticated, forgotPasswordSent, error, authLoading } = auth;

  useEffect(() => {
    if (user !== null) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, user]);

  // handle button click of login form
  const handleForgotPassword = (values) => {
    dispatch(forgotPassword(values));
  };

  return (
    <>
      <AuthLayout>
        <Container fluid>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 1 }} className="px-0">
              <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                {responsiveHero(isAuthenticated)}
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={forgotPasswordSchema}
                  onSubmit={(values) => handleForgotPassword(values)}
                >
                  {({ errors, touched }) => (
                    <>
                      <Form className="auth">
                        <Row>
                          <div>
                            <h2 className="poppins-bold">Forgot your password?</h2>
                            <p>Please provide your email address and we will send you a password reset link.</p>
                          </div>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-4">
                              <label htmlFor="email" className="form-label poppins-light">
                                Email Address
                              </label>
                              <Field name="email" type="email" className={errors.email ? 'form-control error' : 'form-control'} id="email" />
                              {errors.email && touched.email && (
                                <div className="error-msg poppins-light">
                                  <p>
                                    <ErrorMessage name="email" />
                                  </p>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <button type="submit" className="btn brand-primary-bg" disabled={authLoading}>
                              {authLoading ? 'Loading...' : 'Submit'}
                            </button>
                          </Col>
                        </Row>
                        {authError && (
                          <Row>
                            <Col xs={12}>
                              <div className="error-msg poppins-light">{error}</div>
                            </Col>
                          </Row>
                        )}
                        {forgotPasswordSent && (
                          <Row>
                            <Col xs={12}>
                              <div className="mt-3 poppins-light">Please check your email.</div>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Col>
            <Message />
          </Row>
        </Container>
      </AuthLayout>
    </>
  );
};

export default Forgot;
