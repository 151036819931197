import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { setPassword } from 'features/auth/authSlice';
import { history } from '../../helpers/history';
import AuthLayout from 'components/Layouts/AuthLayout';
import Message from 'components/Auth/Message';
import responsiveHero from 'components/Auth/ResponsiveHero';

const setPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Password should contain at least one uppercase, one lowercase, one number and one special character ! @ # $ % ^ & *')
    .min(8, 'Password should be minimum 8 characters')
    .max(30, 'Password should be maximum 30 characters'),
  passwordConfirmation: Yup.string()
    .required('Password required')
    .min(8, 'Password should be minimum 8 characters')
    .max(30, 'Password should be maximum 30 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SetPassword = () => {
  const dispatch = useDispatch();
  // const user = localStorage.getItem('user');
  const { id } = queryString.parse(window.location.search);
  const auth = useSelector((state) => state.auth);
  const { error, authError, isAuthenticated, authLoading, passwordSet } = auth;

  useEffect(() => {
    if (passwordSet && isAuthenticated) {
      history.push('/dashboard');
    }
  }, [dispatch, isAuthenticated, passwordSet]);

  const handleSetPassword = (values) => {
    values.invitationId = id;
    dispatch(setPassword(values));
  };

  return (
    <>
      <AuthLayout>
        <Container fluid>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 1 }} className="px-0">
              <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                {responsiveHero(isAuthenticated)}
                <Formik
                  className="bg-white"
                  initialValues={{
                    password: '',
                    passwordConfirmation: '',
                  }}
                  validationSchema={setPasswordSchema}
                  onSubmit={(values) => handleSetPassword(values)}
                >
                  {({ errors, touched }) => (
                    <>
                      <Form className="auth">
                        <Row>
                          <div>
                            <div>
                              <h2 className="poppins-bold">Set a Password</h2>
                              <p className="subtext">
                                Please set a strong password. This should include at least one uppercase, one lowercase, one number and one special character e.g. ! @ # $ % ^ & *.
                              </p>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Row>
                              <Col>
                                <div className="mb-4">
                                  <label htmlFor="password" className="form-label poppins-light">
                                    Password
                                  </label>
                                  <Field name="password" type="password" className={errors.password ? 'form-control error' : 'form-control'} id="password" />
                                  {errors.password && touched.password && (
                                    <div className="error-msg poppins-light">
                                      <p>
                                        <ErrorMessage name="password" />
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-4">
                                  <label htmlFor="passwordConfirmation" className="form-label poppins-light">
                                    Confirm Password
                                  </label>
                                  <Field name="passwordConfirmation" type="password" className={errors.passwordConfirmation ? 'form-control error' : 'form-control'} id="passwordConfirmation" />
                                  {errors.passwordConfirmation && touched.passwordConfirmation && (
                                    <div className="error-msg poppins-light">
                                      <p>
                                        <ErrorMessage name="passwordConfirmation" />
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <button type="submit" className="btn brand-primary-bg" disabled={authLoading}>
                              {authLoading ? 'Loading...' : 'Set Password'}
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>{authError && <div className="error-msg poppins-light">{error}</div>}</Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Col>
            <Message />
          </Row>
        </Container>
      </AuthLayout>
    </>
  );
};

export default SetPassword;
