/* eslint-disable yoda */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getUser, getUrl } from 'features/auth/authSlice';
import { getCourse } from 'features/dashboard/dashboardSlice';
import MainLayout from 'components/Layouts/MainLayout';
import Header from 'components/Layouts/Header';
import infoIcon from 'assets/icons/info.png';
import { Spinner } from 'components/Spinner';
// import askingTheRightQuestionsPdf from 'assets/files/asking-the–right-questions-case-study.pdf';
import { history } from 'helpers/history';
import ReadingList from './ReadingList';
import YourSchedule from './YourSchedule';

const formatTime = (seconds) => {
  return (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const auth = useSelector((state) => state.auth);
  const { quiz1, quiz2, quiz1Report, quiz2Report, authLoading, isAuthenticated, getUrlPending } = auth;
  const [counter, setCounter] = useState(300);

  useEffect(() => {
    if (user !== null) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user === null && !isAuthenticated) {
      history.push('/login');
    }
  }, [dispatch, user, isAuthenticated]);

  useEffect(() => {
    if (user !== null && isAuthenticated) {
      dispatch(getUrl());
      dispatch(getCourse());
    }
  }, [dispatch, user, isAuthenticated]);

  useEffect(() => {
    if (quiz1Report || quiz2Report) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [quiz1Report, quiz2Report, counter]);

  return (
    <>
      <MainLayout>
        <Header>
          <Container fluid className="quiz-cta">
            <Row className="pt-4">
              <Col md={{ span: 8 }} className="mx-auto text-center">
                <h1 className="dl-title fw-bolder">Welcome to the Data Academy!</h1>
              </Col>
            </Row>
            {!authLoading && isAuthenticated && (
              <Container>
                <Row className="pb-4">
                  <Col md={{ span: 7 }} className="mx-auto text-center dl-copy">
                    {!authLoading && quiz1 !== 'completed' && (
                      <p>
                        Please take time to complete our <span className="dl-name fw-bold">Data Literacy Analysis</span>. This will help us to understand your level of experience and assist us in
                        targeting the areas you may need our help most.
                      </p>
                    )}
                    {!authLoading && quiz1 === 'completed' && !quiz1Report && (
                      <p>
                        Thank you for taking the <span className="dl-name fw-bold">Data Literacy Analysis</span>, we will let you know when your report is ready for review.
                      </p>
                    )}
                    {!authLoading && quiz1 === 'completed' && quiz1Report && (
                      <p>
                        Thank you for taking the <span className="dl-name fw-bold">Data Literacy Analysis</span>, your report is ready. Please now complete the{' '}
                        <span className="dl-name fw-bold">Data Maturity Analysis</span> below.
                      </p>
                    )}

                    {(authLoading || getUrlPending) && (
                      <Link to="" className="btn disabled-link">
                        Loading...
                      </Link>
                    )}
                  </Col>
                </Row>
                <Row className="pb-5">
                  <Col md={{ span: 8 }} lg={{ span: 4, offset: 2 }} className="mx-auto text-center">
                    {!authLoading && !getUrlPending && (
                      <>
                        {quiz1 !== 'completed' && !quiz1Report && (
                          <Link to="/quiz/1" className="btn">
                            {quiz1 === 'begin' && 'Get Started'}
                            {quiz1 === 'in progress' && 'Continue'}
                          </Link>
                        )}

                        {quiz1 === 'completed' && !quiz1Report && (
                          <Link to="" className="btn disabled-link">
                            Report Pending
                          </Link>
                        )}

                        {quiz1 === 'completed' && quiz1Report && (
                          <>
                            <a href={quiz1Report} className={counter > 0 ? 'btn' : 'btn disabled-link'} target="_blank" rel="noreferrer">
                              Download Report
                            </a>
                            <p className="mt-3 mb-0">
                              {counter > 0
                                ? `Your report is available for ${formatTime(counter)}, after which you will need to refresh the browser.`
                                : 'Please refresh the page to retrieve your report again.'}
                            </p>
                          </>
                        )}

                        {!quiz1 && quiz1Report && (
                          <Link to="" className="btn disabled-link">
                            Something went wrong
                          </Link>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          </Container>
        </Header>

        {authLoading && (
          <main className="dashboard">
            <Container fluid>
              <Row className="py-5 bg-white quiz-cta">
                <Col md={{ span: 8 }} lg={{ span: 6 }} className="mx-auto text-center">
                  <Spinner text="Loading..." />
                </Col>
              </Row>
            </Container>
          </main>
        )}

        {!authLoading && isAuthenticated && (
          <main className="dashboard">
            <Container>
            {quiz2 !== 'not applicable' && (
                <Row className="pt-lg-5 content">
                  <Col md={{ span: 10, offset: 1 }} lg={{ span: 12, offset: 0 }}>
                    <Card className="mb-0 data-maturity-cta py-lg-4 p-4">
                      <Row className="h-100">
                        <Col md={{ span: 12 }} lg={{ span: 1 }} className="my-lg-auto mb-md-3">
                          <div className="ms-lg-3 ms-md-0">
                            <img src={infoIcon} className="mx-auto d-block" alt="info icon" />
                          </div>
                        </Col>
                        <Col md={{ span: 12 }} lg={{ span: 8 }} className="my-lg-auto my-3">
                          <div className="my-auto">
                            <p className="card-text copy poppins-light">
                              {!authLoading && quiz2 === 'completed' && (
                                <>
                                  Thank you for taking the{' '}
                                  <span className="poppins-bold">Data Maturity Analysis</span>, we
                                  will let you know when your report is ready for review.
                                </>
                              )}
                              {!authLoading && quiz2 !== 'completed' && (
                                <>
                                  Please complete your{' '}
                                  <span className="poppins-bold">Data Maturity Analysis</span> ahead
                                  of your next training session.
                                </>
                              )}
                            </p>
                          </div>
                        </Col>
                        <Col md={{ span: 12 }} lg={{ span: 3 }} className="my-auto text-center">
                          {(authLoading || getUrlPending) && (
                            <Link to="" className="btn disabled-link">
                              Loading...
                            </Link>
                          )}

                          {!authLoading && !getUrlPending && (
                            <>
                              {quiz2 !== 'completed' && !quiz2Report && (
                                <Link to="/quiz/2" className="btn">
                                  {quiz2 === 'begin' && 'Start Analysis'}
                                  {quiz2 === 'in progress' && 'Continue'}
                                </Link>
                              )}

                              {quiz2 === 'completed' && !quiz2Report && (
                                <Link to="" className="btn disabled-link">
                                  Report Pending
                                </Link>
                              )}

                              {quiz2 === 'completed' && quiz2Report && (
                                <>
                                  <a
                                    href={quiz2Report}
                                    className={counter > 0 ? 'btn' : 'btn disabled-link'}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download Report
                                  </a>
                                  <p className="mt-3 mb-0">
                                    {counter > 0
                                      ? `Your report is available for ${formatTime(
                                          counter
                                        )}, after which you will need to refresh the browser.`
                                      : 'Please refresh the page to retrieve your report again.'}
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              )}
              <Row className="pt-lg-5 pb-lg-5 content">
                <YourSchedule />
                <ReadingList />
              </Row>
            </Container>
          </main>
        )}
      </MainLayout>
    </>
  );
};

export default Dashboard;
