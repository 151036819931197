import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { history } from '../../helpers/history';

import { validateInvitation, clearState } from 'features/auth/authSlice';
import { Spinner } from 'components/Spinner';
import AuthLayout from 'components/Layouts/AuthLayout';
import Message from 'components/Auth/Message';

const Invitation = () => {
  const { id } = queryString.parse(window.location.search);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { authLoading, authError, invitationVerified, passwordSet } = auth;

  useEffect(() => {
    dispatch(clearState());
    dispatch(validateInvitation(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (invitationVerified && !passwordSet) {
      history.push(`/setpassword?id=${id}`);
    }

    if (invitationVerified && passwordSet) {
      history.push(`/login`);
    }
  }, [invitationVerified, passwordSet, id]);

  return (
    <>
      <AuthLayout>
        <Container fluid>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 5, offset: 1 }}>
              <div className="vh-100 d-flex align-items-center">
                <>
                  <div className="auth">
                    {authLoading && !invitationVerified && <Spinner text="Loading..." />}
                    {authError && <h2 className="invitation-error poppins-light">Sorry, you are currently unable to take part.</h2>}
                  </div>
                </>
              </div>
            </Col>
            <Col md={{ span: 4 }} xl={{ span: 5 }} className="d-sm-none d-md-block">
              <Message />
            </Col>
          </Row>
        </Container>
      </AuthLayout>
    </>
  );
};

export default Invitation;
