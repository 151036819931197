/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'helpers/apiClient';

const initialState = {
  course: null,
  modules: {
    past: [],
    current: []
  },
  assets: [],
  status: 'idle',
};

export const getCourse = createAsyncThunk(
  'dashboard/getCourse',
  async (value, { rejectWithValue }) => {
    try {
      const response = await api('GET', `${process.env.REACT_APP_API_BASE_URL}/course`, null, true);
      const res = await response.json();
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCourse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.course = action.payload.course;
        state.modules = action.payload.modules;
        state.assets = action.payload.assets;
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { saveAnswer } = dashboardSlice.actions;

export default dashboardSlice.reducer;
