import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';
import authBg from 'assets/images/Data_Academy_Login.png';
import logoAuth from 'assets/images/Data-Academy-logo-white.svg';

const ResponsiveHero = (isAuthenticated) => {
  return (
    <>
      <Col className="d-block d-md-none">
        <div>
          <img src={authBg} className="img-responsive mobile" alt="background" />
          <p className="auth-message-mobile poppins-bold">
            Ready to start your journey in&nbsp;<span className="brand-gradient">good data management?</span>
          </p>
          <Link to={isAuthenticated ? '/dashboard' : '/login'} className="logo-link">
            <Logo className="logo-auth" logo={logoAuth} />
          </Link>
          <p className="footer-text">Data Academy by Profusion @ 2021. All rights reserved.</p>
        </div>
      </Col>
    </>
  );
};

export default ResponsiveHero;
