import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { userLogin } from 'features/auth/authSlice';
import { history } from '../../helpers/history';
import AuthLayout from 'components/Layouts/AuthLayout';
import Message from 'components/Auth/Message';
import responsiveHero from 'components/Auth/ResponsiveHero';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email required'),
  password: Yup.string()
    .required('Password required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Password should contain at least one uppercase, one lowercase, one number and one special character ! @ # $ % ^ & *')
    .min(8, 'Password should be minimum 8 characters')
    .max(30, 'Password should be maximum 30 characters'),
});

const Login = () => {
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const authObj = useSelector((state) => state.auth);
  const { authError, isAuthenticated, error, authLoading } = authObj;

  useEffect(() => {
    if (user !== null) {
      history.push('/dashboard');
    }
  }, [dispatch, isAuthenticated, user]);

  // handle button click of login form
  const handleLogin = (values) => {
    dispatch(userLogin(values));
  };

  return (
    <>
      <AuthLayout>
        <Container fluid>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 1 }} className="px-0">
              <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                {responsiveHero(isAuthenticated)}
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {({ errors, touched }) => (
                    <>
                      <Form className="auth">
                        <Row>
                          <div>
                            <div>
                              <h2 className="poppins-bold">Login</h2>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Row>
                              <Col>
                                <div className="mb-4">
                                  <label htmlFor="email" className="form-label poppins-light">
                                    Email Address
                                  </label>
                                  <Field name="email" type="email" className={errors.email ? 'form-control error' : 'form-control'} id="email" />
                                  {errors.email && touched.email && (
                                    <div className="error-msg poppins-light">
                                      <p>
                                        <ErrorMessage name="email" />
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-4">
                                  <label htmlFor="password" className="form-label poppins-light">
                                    Password
                                  </label>
                                  <Field name="password" type="password" className={errors.password ? 'form-control error' : 'form-control'} id="password" />
                                  {errors.password && touched.password && (
                                    <div className="error-msg poppins-light">
                                      <p>
                                        <ErrorMessage name="password" />
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <button type="submit" className="btn brand-primary-bg" disabled={authLoading}>
                              {authLoading ? 'Loading...' : 'Login'}
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="mt-3 mb-3">
                              <Link to="/forgotpassword" className="poppins-light">
                                Forgot password?
                              </Link>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>{authError && <div className="error-msg poppins-light">{error}</div>}</Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Col>
            <Message />
          </Row>
        </Container>
      </AuthLayout>
    </>
  );
};

export default Login;
