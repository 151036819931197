/* eslint-disable react/prop-types */
import React from 'react';
import { Row } from 'react-bootstrap';

const Header = ({ bg = 'header-bg', children }) => {
  return (
    <>
      <Row className={`text-center ${bg} py-5`}>
        {/* <div className="mx-auto">{children}</div> */}
        {children}
      </Row>
    </>
  );
};

export default Header;
