import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Card } from 'react-bootstrap';
import { Spinner } from 'components/Spinner';

const renderReadingList = (reading) => {
  return reading.map((asset) => {
    return (
      <Card key={asset.id}>
        <Card.Body>
          <p className="name">{asset.name}</p>
          {asset.description != null && (
            <p className="description">{asset.description}</p>
          )}
          <p className="file-size">{asset.size}</p>
          <a
            download={asset.name}
            target="_blank"
            rel="noreferrer"
            href={`module_assets/${asset.location}`}
            className="download"
          >
            <span className="title">Download</span>
            <i className="material-icons">east</i>
          </a>
        </Card.Body>
      </Card>
    );
  });
};

const response = (message) => {
  return (
    <Card>
      <Card.Body>
        <p className="name mb-2">{message}</p>
      </Card.Body>
    </Card>
  );
};

const ReadingList = () => {
  const dashboard = useSelector((state) => state.dashboard);
  const { assets, status } = dashboard;
  const count = assets.length;

  return (
    <>
      <Col
        lg={{ span: 5, offset: 0 }}
        md={{ span: 10, offset: 1 }}
        className="reading-list-and-materials"
      >
        <Card>
          <Card.Header className="">Reading list and Materials</Card.Header>
          {(status === 'idle' || status === 'loading') && (
          <Card.Body>
            <Spinner text="Loading..." />
          </Card.Body>
          )}
        </Card>

        {status === 'succeeded' && (
          <>
            {count !== 0 && renderReadingList(assets)}
            {count === 0 && response('No pre-reading for your next training session.')}
          </>
        )}

        {status === 'failed' && (
        <>
          {count !== 0 && renderReadingList(assets)}
          {count === 0 && response('Sorry, something went wrong.')}
        </>
        )}

      </Col>
    </>
  );
};

export default ReadingList;
