/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from 'react-bootstrap';

import NavBar from 'components/Main/NavBar';

const MainLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <Container fluid>{children}</Container>
    </>
  );
};

export default MainLayout;
