/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

const renderCategories = (props) => {
  const { categories, current } = props;
  return categories.map((category) => {
    let active = category.orderNum === current ? 'active' : '';
    return (
      <Fragment key={category.id}>
        <li className={`breadcrumb-item poppins-light ${active}`} aria-current="page">
          {category.name}
        </li>
      </Fragment>
    );
  });
};

// const renderCategories2 = (props) => {
//   const { categories, current } = props;
//   return categories.map((category) => {
//     let active = category.orderNum === current ? 'active' : '';
//     return (
//       <Fragment key={category.id}>
//         <a href="#home" className={`new-breadcrumb ${active}`} aria-current="page">
//           {category.name}
//         </a>
//       </Fragment>
//     );
//   });
// };

const Categories = (props) => {
  return (
    <>
      <Row className="text-center breadcrumb-container bg-white">
        <Col md={{ span: 8, offset: 2 }}>
          <div className="test">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb insidescroll">{renderCategories(props)}</ol>
            </nav>
          </div>
        </Col>

        {/* <Col md={{ span: 8, offset: 2 }}>
          <div className="breadcrumb">
            <div className="insidescroll">{renderCategories2(props)}</div>
          </div>
        </Col> */}
      </Row>
    </>
  );
};

export default Categories;
