import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col } from 'react-bootstrap';

import { history } from '../../helpers/history';
import Questions from './Questions';
// import Questions from '../../helpers/formik/formikExample';
import { getQuiz } from './quizSlice';
import { Spinner } from 'components/Spinner';
import MainLayout from 'components/Layouts/MainLayout';
import Header from 'components/Layouts/Header';
import Categories from 'components/Categories';
import { saveQuiz } from './quizSlice';

const getCurrentQuestions = (questions, currentCategory) => {
  return questions.filter((question) => {
    if (question.categoryOrder === currentCategory) {
      return question;
    }
    return false;
  });
};

const Quiz = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const quiz = useSelector((state) => state.quiz);
  let { status, questions, currentCategory, categories } = quiz;
  const quizTitle = id === '1' ? 'Data Literacy Analysis' : 'Data Maturity Analysis';

  useEffect(() => {
    if (user) {
      dispatch(getQuiz(id));
    }
  }, [dispatch, id, user]);

  const prepAndSave = async (values) => {
    let data = {
      quizId: id,
      currentCategory: currentCategory,
      answers: values,
    };

    dispatch(saveQuiz(data));
  };

  useEffect(() => {
    if (status === 'completed') {
      history.push('/dashboard');
    }
  }, [status]);

  return (
    <>
      <MainLayout header="Data Academy Logo">
        <Header bg="quiz-hero-bg">
          <Col xl={{ span: 8, offset: 2 }}>
            <div className="d-flex justify-content-center">
              <div>
                <h1 className="text-white poppins-bold">{quizTitle}</h1>
                {id === '1' && (
                  <>
                    <p className="quiz-message-subtext">
                      Please complete the questions below to your best knowledge. It will help us know where you are in your understanding of some key data concepts, interpreting data, reading charts
                      and getting value out of your data projects. If you are unsure of any answers please do not guess but select “I don’t know”.
                    </p>
                    <p className="quiz-message-subtext">You will receive a report ahead of your training with your results.</p>
                  </>
                )}
                {id === '2' && (
                  <>
                    <p className="quiz-message-subtext">
                      The Data Maturity Analysis is designed to help us understand where your organisation is on its journey with data & AI and recommend areas of focus so you can fully harness the
                      power of data to inform decisions across your business. Whilst you work through, please feel free to ask your colleagues for help to answer relevant questions.
                    </p>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Header>
        <main className="quiz">
          <Categories categories={categories} current={currentCategory} />
          {status === 'succeeded' && (
            <>
              <Questions questions={getCurrentQuestions(questions, currentCategory)} prepAndSave={prepAndSave} />
            </>
          )}
          <Row className="text-center mt-5">
            <div className="mx-auto">
              {status === 'loading' && <Spinner text="Loading..." size="5rem" />}
              {status === 'completed' && <h2>You completed! :-)</h2>}
            </div>
          </Row>
        </main>
      </MainLayout>
    </>
  );
};

export default Quiz;
