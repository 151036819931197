/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
import AsyncStorage from '@createnextapp/async-local-storage';

export const api = (methodType, url, data, isProtected) => {
  return new Promise((resolve, reject) => {
    const payload = {
      method: methodType,
      headers: {},
    };
    if (data !== null) {
      payload.body = JSON.stringify(data);
    }

    /**
     * "isProtected" is used for API calls with authToken
     */
    if (isProtected) {
      AsyncStorage.getItem('token').then((accessKey) => {
        payload.headers.Authorization = `Bearer ${accessKey.replace(/\"/g, '')}`;

        fetch(url, payload)
          .then((response) => {
            if (response.status === 401) {
              // refresh token
              AsyncStorage.getItem('refreshToken').then((refreshKey) => {
                const rKey = refreshKey.replace(/\"/g, '');
                const payloadRef = {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${rKey}`,
                  },
                };
                /*
                 * Refresh the access token
                 */
                fetch(`${process.env.REACT_APP_API_BASE_URL}/user/refreshtoken`, payloadRef).then(async (response) => {
                  /*
                   * if refresh token expired. redirect to login page
                   */
                  if (response.status !== 200) {
                    console.log('refresh token is invalid');
                    const res = await response.json();
                    reject(res);
                  } else if (response.status === 200) {
                    const newToken = await response.json();
                    const { token } = newToken;
                    AsyncStorage.setItem('token', token).then(() => {
                      payload.headers.Authorization = `Bearer ${token.replace(/\"/g, '')}`;
                      fetch(url, payload)
                        .then(async (response) => {
                          if (response.status !== 200) {
                            const res = await response.json();
                            reject(res);
                          }
                          resolve(response);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    });
                  }
                });
              });
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      fetch(url, payload)
        .then(async (response) => {
          if (response.status !== 200) {
            const res = await response.json();
            reject(res);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};
