/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Logo from 'components/Logo';
import logoAuth from 'assets/images/Data-Academy-logo-white.svg';

const AuthLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;

  return (
    <>
      <main className="auth-wrapper">
        <div className="d-none d-md-block">
          <Link to={isAuthenticated ? '/dashboard' : '/login'} className="logo-link">
            <Logo className="logo-auth" logo={logoAuth} />
          </Link>
          <p className="footer-text">Data Academy by Profusion @ 2021. All rights reserved.</p>
        </div>
        {children}
      </main>
    </>
  );
};

export default AuthLayout;
