/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { useSelector } from "react-redux";
import { Col, Card, Accordion } from "react-bootstrap";
import DOMPurify from "dompurify";
import { Spinner } from "components/Spinner";
import { getStandardDate, formatTime } from "helpers/date-time-formatter";

const singleModule = (module) => {
  return (
    <Card key={module.startTime}>
      <Card.Body>
        <Card.Title className='module'>{`Module ${module.title}`}</Card.Title>
        <p className='name'>{module.name}</p>
        {module.type === "module" && (
          <>
            <p className='duration'>0.5 days</p>
            <p className='location'>
              <span className='span-lora-bold'>Location:</span>
              &nbsp; Telephone House, 69-77 Paul Street, Old Street, London EC2A
              4NW
            </p>
            <p className='date'>
              <span className='span-lora-bold'>Date:</span>
              &nbsp;
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(getStandardDate(module.startTime)),
                }}
              />
            </p>
            <p className='time'>
              <span className='span-lora-bold'>Time:</span>
              &nbsp;
              {formatTime(module.startTime, module.endTime)}
            </p>
            <hr className='border-2 border-top' />
            <p className='name'>Preparation</p>
            <p
              className='description'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(module.preparation).replace(
                  /href/g,
                  "target='_blank' href"
                ),
              }}
            />
          </>
        )}
        {module.type === "assignment" && (
          <p className='deadline'>
            <span className='span-lora-bold'>Deadline:</span>
            &nbsp;
            {getStandardDate(module.startTime)}
          </p>
        )}
      </Card.Body>
    </Card>
  );
};

const doubleModule = (modules) => {
  const [moduleA, moduleB] = modules.map((x) => x);
  return (
    <Card key={moduleA.startTime}>
      <Card.Body>
        <Card.Title className='module'>
          {`Modules ${moduleA.title} and ${moduleB.title}`}
        </Card.Title>
        <p className='name'>{`${moduleA.name} and ${moduleB.name}`}</p>
        <p className='duration'>1 day</p>
        <p className='location'>
          <span className='span-lora-bold'>Location:</span>
          &nbsp; Telephone House, 69-77 Paul Street, Old Street, London EC2A 4NW
        </p>
        <p className='date'>
          <span className='span-lora-bold'>Date:</span>
          &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(getStandardDate(moduleA.startTime)),
            }}
          />
        </p>
        <p className='time'>
          <span className='span-lora-bold'>Time:</span>
          &nbsp;
          {formatTime(moduleA.startTime, moduleB.endTime)}
        </p>
        <hr className='border-2 border-top' />
        <p className='name'>Preparation</p>
        {/* weird bug, target="_blank" attribute was being lost between redux and dangerouslySetInnerHTML, investigate further */}
        <p
          className='description'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(moduleA.preparation).replace(
              /href/g,
              "target='_blank' href"
            ),
          }}
        />
      </Card.Body>
    </Card>
  );
};

const renderModules = (days) => {
  return days.map((modules) => {
    if (modules.length > 1) {
      return doubleModule(modules);
    }
    return singleModule(modules[0]);
  });
};

const response = (message) => {
  return (
    <Card>
      <Card.Body>
        <p className='name mb-2'>{message}</p>
      </Card.Body>
    </Card>
  );
};

const countModules = (modules) => {
  return modules.reduce((acc, curr) => {
    acc += curr.length;
    return acc;
  }, 0);
};

const YourSchedule = () => {
  const dashboard = useSelector((state) => state.dashboard);
  const { modules, status } = dashboard;

  return (
    <>
      <Col
        lg={{ span: 7, offset: 0 }}
        md={{ span: 10, offset: 1 }}
        className='your-schedule'
      >
        <Card>
          <Card.Header className=''>Your Schedule</Card.Header>
          {(status === "idle" || status === "loading") && (
            <Card.Body>
              <Spinner text='Loading...' />
            </Card.Body>
          )}
        </Card>

        {status === "succeeded" && (
          <>
            {modules.past.length > 0 && (
              <Card className='past-modules'>
                <Accordion flush>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header className='name'>
                      Past modules
                    </Accordion.Header>
                    <p className='module-count'>
                      {countModules(modules.past)} modules
                    </p>
                    <Accordion.Body>
                      {renderModules(modules.past)}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card>
            )}
            {modules.current.length > 0 && renderModules(modules.current)}
            {modules.current.length === 0 && response("No modules available.")}
          </>
        )}

        {status === "failed" && response("Sorry, something went wrong.")}
      </Col>
    </>
  );
};

export default YourSchedule;
