import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { withFormikDevtools } from 'formik-devtools-extension';
import { renderQuestion, renderRadioAnswers, renderCheckboxAnswers, renderImage, setBgColor } from './quizHelper';
import { setValidationRules, setInitialValues } from './formikHelper';

const Questions = ({ questions, prepAndSave }) => {
  const initialValues = setInitialValues(questions);
  const validationRules = setValidationRules(questions);
  const quizSchema = Yup.object().shape(validationRules);

  return (
    <Row className="questions">
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={quizSchema}
          onSubmit={async (values) => {
            await prepAndSave(values);
          }}
        >
          {(formikProps) => {
            // withFormikDevtools(formikProps); // use this callback to pass props to extension
            const { errors, touched } = formikProps;
            return (
              <Form className="form">
                {questions.map((question, index) => {
                  return (
                    <Fragment key={question.id}>
                      <Row className={`${setBgColor(index)} question-container`}>
                        <Col md={{ span: 8, offset: 2 }} className="px-sm-4 px-md-0">
                          <fieldset>
                            {renderQuestion(question, index)}
                            {question.imageurl && renderImage(question)}
                            {question.type === 'radio' && renderRadioAnswers(question)}
                            {question.type === 'checkbox' && renderCheckboxAnswers(question)}
                          </fieldset>
                          {errors[`${question.id}`] && touched[`${question.id}`] && (
                            <div className="error-msg poppins-light">
                              <p style={{ color: 'red', marginTop: 10 }}>
                                <ErrorMessage name={`${question.id}`} />
                              </p>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Fragment>
                  );
                })}

                <Row>
                  <Col xs={{ span: 3, offset: 8 }} md={{ span: 3, offset: 9 }}>
                    <button type="submit" className="btn quiz mb-5 brand-primary-bg">
                      Submit
                    </button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default Questions;
