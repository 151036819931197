import React, { Fragment } from 'react';
import { Field } from 'formik';
import DOMPurify from 'dompurify';

export const renderRadioAnswers = (question) => {
  const { answers } = question;
  return answers.map((answer) => (
    <Fragment key={answer.id}>
      <label className="checkbox-label radio-container d-flex" htmlFor={answer.id}>
        <Field type="radio" name={`${question.id}`} id={answer.id} value={`${answer.id}`} />
        <span className="radio"></span>
        <span className="radio-text fs-6">{answer.answer}</span>
      </label>
    </Fragment>
  ));
};

export const renderCheckboxAnswers = (question) => {
  const { answers } = question;
  return answers.map((answer) => (
    <Fragment key={answer.id}>
      <div className="mb-3">
        <label className="checkbox-label checkbox-container d-flex" htmlFor={`${answer.id}`}>
          <Field type="checkbox" className="btn-check" name={`${question.id}`} id={`${answer.id}`} value={`${answer.id}`} />
          <span className="checkmark"></span>
          <span className="checkbox-text fs-6">{answer.answer}</span>
        </label>
      </div>
    </Fragment>
  ));
};

export const renderQuestion = (question, index) => {
  let questionNumber = question.quizId === 2 ? question.questionOrder : index + 1;
  return (
    <legend>
      <h2 className="question fs-4 poppins-bold" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${questionNumber}. ${question.question}`) }}></h2>
    </legend>
  );
};

export const renderImage = (question) => {
  return <img className="question-img" alt="additional content for the question" src={`https://profusion.com/app/uploads/2021/10/${question.imageurl}`} />;
};

export const isOdd = (num) => num % 2;

export const setBgColor = (index) => {
  // return isOdd(index) ? 'bg-white' : 'bg-grey';
  return 'bg-grey';
};
