import { Col } from 'react-bootstrap';

const Message = () => {
  return (
    <>
      <Col md={{ span: 5 }} className="d-none d-xl-block">
        <div className="vh-100 d-flex align-items-center">
          <div className="ps-5">
            <p className="auth-message poppins-bold">
              Ready to start your journey in&nbsp;<span className="brand-gradient">good data management?</span>
            </p>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Message;
