/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// handle the private routes
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (localStorage.getItem('user') !== null ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)} />
);

export default PrivateRoute;
